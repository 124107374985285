<template>
    <div class="user-info">
        <el-image
            :src="avatar ? avatar : 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/orderDefault.png'"></el-image>
        <div class="name">{{ name || '--' }}</div>
    </div>
</template>

<script>
export default {
    props: ['name', 'avatar']
}
</script>

<style lang="less" scoped>
.user-info {
    display: flex;
    align-items: center;

    .el-image {
        width: 40px;
        height: 40px;
        margin-right: 10px;
        flex-shrink: 0;
        border-radius: 50%;
    }

    .name {
        flex: 1;
        text-align: left;
    }
}</style>
